// src/components/FreeTrialButton.tsx

import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import TermsAcceptance from './TermsAcceptance';
import { useNavigate } from 'react-router-dom';
import useIsMobile from '../hooks/useIsMobile';
import { NotificationContext } from '../contexts/NotificationContext';

interface FreeTrialButtonProps {
  onTrialActivated?: () => void;
}

const FreeTrialButtonStyled = styled.button`
  user-select: none;
  background-color: #ff4500;
  color: white;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
  border: none;
  display: inline-block;
  box-shadow: 0 5px 15px rgba(255, 69, 0, 0.3);

  &:hover {
    background-color: #ff6347;
    transform: scale(1.05);
    box-shadow: 0 7px 20px rgba(255, 99, 71, 0.4);
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const FreeTrialButton: React.FC<FreeTrialButtonProps> = ({
  onTrialActivated,
}) => {
  const [showTerms, setShowTerms] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const navigate = useNavigate();
  const termsVersion = '1.0';
  const isMobile = useIsMobile();

  const { showNotification } = useContext(NotificationContext);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await fetch(
            'https://riskmaster.pro/fetch_user_data_proxy.php',
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );

          const data = await response.json();
          if (data.status === 'success') {
            setUserData(data.user);
          } else {
            showNotification(data.message);
          }
        } catch (error) {
          showNotification('Failed to fetch user data');
          console.error(error);
        }
      } else {
        setUserData(null);
        showNotification('No token found. Please log in.');
      }
    };

    fetchUserData();
  }, [showNotification]);

  const handleFreeTrialClick = async () => {
    const token = localStorage.getItem('token');

    if (window.fbq && token) {
      window.fbq('track', 'FreeTrialClick');
    }

    if (!token) {
      navigate('/login');
      return;
    }

    if (userData) {
      try {
        const response = await fetch(
          'https://riskmaster.pro/check_terms_acceptance_proxy.php',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              user_id: userData.id,
              version: termsVersion,
            }),
          }
        );

        const data = await response.json();

        if (data.status === 'accepted') {
          await activateFreeTrial();
        } else {
          setShowTerms(true);
        }
      } catch (error) {
        console.error('Failed to check ToS acceptance:', error);
        showNotification('Failed to check Terms of Service acceptance.');
      }
    }
  };

  const handleTermsAccepted = async () => {
    setShowTerms(false);
    await activateFreeTrial();
  };

  const activateFreeTrial = async () => {
    try {
      const response = await fetch(
        'https://riskmaster.pro/activate_free_trial_proxy.php',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: userData.id,
          }),
        }
      );

      const data = await response.json();
      if (data.status === 'success') {
        showNotification(data.message);

        if (window.fbq) {
          window.fbq('track', 'FreeTrialActivated');
        }

        if (onTrialActivated) {
          onTrialActivated();
        }

        setTimeout(() => {
          navigate('/account');
        }, 3000);
      } else {
        showNotification(data.message || 'An error occurred while activating your free trial.');
      }
    } catch (error) {
      showNotification('Failed to activate free trial. Please try again later.');
      console.error('Failed to activate free trial:', error);
    }
  };

  const handleExit = () => {
    setShowTerms(false);
  };

  return (
    <>
      <FreeTrialButtonStyled onClick={handleFreeTrialClick} disabled={isMobile && !userData}>
        Free Trial
      </FreeTrialButtonStyled>

      {showTerms && userData && (
        <TermsAcceptance
          userId={userData.id}
          version={termsVersion}
          onAccepted={handleTermsAccepted}
          onExit={handleExit}
        />
      )}
    </>
  );
};

export default FreeTrialButton;
