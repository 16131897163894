// src/pages/Account.tsx

import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import FreeTrialButton from '../components/FreeTrialButton';
import { NotificationContext } from '../contexts/NotificationContext'; // Import the NotificationContext
import useIsMobile from '../hooks/useIsMobile'; // Import the custom hook

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--color-background);
  color: var(--color-text);

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Sidebar = styled.nav`
  width: 100%;
  background-color: var(--color-card-bg);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  max-height: 100vh;

  @media (min-width: 768px) {
    width: 250px;
    flex-direction: column;
    padding: 2rem;
  }
`;

const SidebarLink = styled(Link)`
  display: block;
  color: var(--color-text);
  text-decoration: none;
  padding: 0.5rem 0;
  transition: color 0.3s ease;

  &:hover {
    color: var(--color-primary);
    padding: 1rem;
  }
`;

const MainContent = styled.main`
  flex: 1;
  padding: 1rem;

  @media (min-width: 768px) {
    padding: 2rem;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    margin-bottom: 2rem;
  }
`;

const Title = styled.h1`
  color: var(--color-primary);
  font-size: 1.5rem;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const Section = styled.section`
  background-color: var(--color-card-bg);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    padding: 1.5rem;
    margin-bottom: 2rem;
  }
`;

const SectionTitle = styled.h2`
  color: var(--color-primary);
  margin-bottom: 1rem;
`;

const InfoItem = styled.p`
  margin-bottom: 0.5rem;
`;

const SupportButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Button = styled.button`
  width: 100%;
  margin-top: 0.5rem;
  background-color: var(--color-primary);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-secondary);
  }

  @media (min-width: 768px) {
    width: auto;
    margin-top: 0;
    margin-left: 0.5rem;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid var(--color-border);
  font-size: 1rem;
`;

const Guide = styled.p`
  font-size: 0.9rem;
  color: var(--color-text);
  margin-bottom: 1rem;
`;

const DeviceTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  overflow-x: auto;

  th,
  td {
    border: 1px solid var(--color-border);
    padding: 0.5rem;
    text-align: left;
    font-size: 0.8rem;
  }

  th {
    background-color: var(--color-card-bg);
  }
`;

interface UserData {
  id: number;
  fullName: string;
  email: string;
  licenseKey: string;
  licenseStatus: string;
  subscriptionPlan: string;
  machineIds: string[];
  trialLicense?: {
    licenseKey: string;
    expirationDate: string;
  } | null;
}

interface AccountProps {
  setIsLoggedIn: (isLoggedIn: boolean) => void;
}

const Account: React.FC<AccountProps> = ({ setIsLoggedIn }) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [activeSection, setActiveSection] = useState('profile');
  const [licenseKey, setLicenseKey] = useState<string>('');
  const [machineIds, setMachineIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile(); // Use the custom hook

  // Access the Notification Context
  const { showNotification } = useContext(NotificationContext);

  // Meta Pixel: Track Page View on Account Load
  useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'ViewContent', { page: 'Account' });
    }
  }, []);

  // Handle incoming notification messages from navigation state
  useEffect(() => {
    if (location.state && (location.state as any).message) {
      showNotification((location.state as any).message);
      navigate(location.pathname, { replace: true, state: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, showNotification]);

  // Fetch user data
  const fetchUserData = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await axios.get(
          'https://riskmaster.pro/fetch_user_data_proxy.php',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;
        if (data.status === 'success') {
          setUserData(data.user);
          setMachineIds(data.user.machineIds || []);
          setIsLoading(false);
        } else {
          showNotification(data.message);
          setIsLoading(false);
        }
      } catch (error) {
        showNotification('Error fetching user data.');
        setIsLoading(false);
      }
    } else {
      showNotification('No token found. Please log in.');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleSectionChange = (section: string) => {
    setActiveSection(section);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    navigate('/');
    showNotification('Logged out successfully.');
  };

  const handleLicenseRegister = async () => {
    const token = localStorage.getItem('token');
    if (!licenseKey) {
      showNotification('License key is required.');
      return;
    }

    if (!userData || !userData.id) {
      showNotification('User data is missing.');
      return;
    }

    try {
      const registerResponse = await axios.post(
        'https://riskmaster.pro/license_register_proxy.php',
        { license_key: licenseKey },
        { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
      );

      if (registerResponse.data.status === 'success') {
        const linkResponse = await axios.post(
          'https://riskmaster.pro/link_license_proxy.php',
          { license_key: licenseKey, user_id: userData.id },
          { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
        );

        if (linkResponse.data.status === 'success') {
          showNotification('License linked and registered successfully.');
          setLicenseKey('');
          fetchUserData();
          window.fbq && window.fbq('track', 'LicenseRegistered');
        } else {
          showNotification(linkResponse.data.message);
        }
      } else {
        showNotification(registerResponse.data.message);
      }
    } catch (error) {
      showNotification('Failed to register or link license.');
    }
  };

  const handleLicenseRemove = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        'https://riskmaster.pro/remove_license_proxy.php',
        {},
        { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
      );

      if (response.data.status === 'success') {
        showNotification('License removed successfully.');
        fetchUserData();
        window.fbq && window.fbq('track', 'LicenseRemoved');
      } else {
        showNotification(response.data.message);
      }
    } catch (error) {
      showNotification('Failed to remove license.');
    }
  };

  const handleRemoveMachine = async (machineId: string) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        'https://riskmaster.pro/remove_machine_proxy.php',
        { machine_id: machineId },
        { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
      );

      if (response.data.status === 'success') {
        showNotification('Machine ID removed successfully.');
        setMachineIds(machineIds.filter((id) => id !== machineId));
        window.fbq && window.fbq('track', 'MachineRemoved');
      } else {
        showNotification(response.data.message);
      }
    } catch (error) {
      showNotification('Failed to remove machine.');
    }
  };

  const renderContent = () => {
    if (isLoading) return <Section>Loading...</Section>;

    switch (activeSection) {
      case 'profile':
        return (
          <Section>
            <SectionTitle>Profile Information</SectionTitle>
            <InfoItem><strong>Name:</strong> {userData?.fullName}</InfoItem>
            <InfoItem><strong>Email:</strong> {userData?.email}</InfoItem>
            <Link to="/profile-edit" state={{ from: 'account', message: 'Profile updated successfully.' }}>
              <Button onClick={() => window.fbq && window.fbq('track', 'EditProfile')}>Edit Profile</Button>
            </Link>
          </Section>
        );
      case 'license':
        return (
          <Section>
            <SectionTitle>License Information</SectionTitle>
            <InfoItem><strong>Status:</strong> {userData?.licenseStatus}</InfoItem>
            <InfoItem><strong>License Key:</strong> {userData?.licenseKey || 'No License Registered'}</InfoItem>
            {userData?.licenseKey === 'No license key found' ? (
              <>
                <Input type="text" value={licenseKey} onChange={(e) => setLicenseKey(e.target.value)} placeholder="Enter License Key" />
                <Button onClick={handleLicenseRegister}>Register License</Button>
              </>
            ) : (
              <Button onClick={handleLicenseRemove}>Remove License</Button>
            )}
            <SectionTitle>Registered Devices</SectionTitle>
            <Guide>Here you can see the devices registered with your license.</Guide>
            {machineIds.length > 0 ? (
              <DeviceTable>
                <thead>
                  <tr><th>Device ID</th><th>Action</th></tr>
                </thead>
                <tbody>
                  {machineIds.map((id) => (
                    <tr key={id}>
                      <td>{id}</td>
                      <td><Button onClick={() => handleRemoveMachine(id)}>Remove</Button></td>
                    </tr>
                  ))}
                </tbody>
              </DeviceTable>
            ) : (
              <InfoItem>No registered devices.</InfoItem>
            )}
            {userData?.licenseKey === 'No license key found' ? (
              userData.trialLicense ? (
                <>
                  <SectionTitle>Trial License</SectionTitle>
                  <InfoItem><strong>Trial License Key:</strong> {userData.trialLicense.licenseKey}</InfoItem>
                  <InfoItem><strong>Expires On:</strong> {new Date(userData.trialLicense.expirationDate).toLocaleString()}</InfoItem>
                </>
              ) : (
                <>
                  <SectionTitle>Free Trial</SectionTitle>
                  <Guide>You have not activated a free trial yet.</Guide>
                  <FreeTrialButton onTrialActivated={() => { fetchUserData(); window.fbq && window.fbq('track', 'StartTrial'); }} />
                </>
              )
            ) : null}
          </Section>
        );
      case 'support':
        return (
          <Section>
            <SectionTitle>Support</SectionTitle>
            <SupportButtonContainer>
              <Button onClick={() => { window.location.href = 'mailto:support@riskmaster.pro'; window.fbq && window.fbq('track', 'ContactSupport'); }}>Contact Support</Button>
              <Link to="/help-center"><Button>Visit Help Center</Button></Link>
            </SupportButtonContainer>
          </Section>
        );
      default:
        return null;
    }
  };

  return (
    <DashboardContainer>
      <Sidebar>
        <SidebarLink to="/">← Back to Home</SidebarLink>
        <SidebarLink to="#" onClick={() => handleSectionChange('profile')}>Profile</SidebarLink>
        <SidebarLink to="#" onClick={() => handleSectionChange('license')}>License</SidebarLink>
        <SidebarLink to="#" onClick={() => handleSectionChange('support')}>Support</SidebarLink>
      </Sidebar>
      <MainContent>
        <Header>
          <Title>My Account</Title>
          <Button onClick={handleLogout}>Logout</Button>
        </Header>
        {renderContent()}
      </MainContent>
    </DashboardContainer>
  );
};

export default Account;
