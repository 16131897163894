// src/contexts/NotificationContext.tsx

import React, { createContext, useState, ReactNode } from 'react';
import Snackbar from '../components/Snackbar';
import Toast from '../components/Toast';
import useIsMobile from '../hooks/useIsMobile';

interface NotificationContextType {
  showNotification: (message: string) => void;
}

export const NotificationContext = createContext<NotificationContextType>({
  showNotification: () => {},
});

interface NotificationProviderProps {
  children: ReactNode;
}

const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [toastOpen, setToastOpen] = useState<boolean>(false);
  const isMobile = useIsMobile();

  const showNotification = (message: string) => {
    setNotificationMessage(message);
    if (isMobile) {
      setSnackbarOpen(true);
    } else {
      setToastOpen(true);
    }
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      <Snackbar
        message={notificationMessage}
        isOpen={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
      <Toast
        message={notificationMessage}
        isOpen={toastOpen}
        onClose={() => setToastOpen(false)}
      />
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
