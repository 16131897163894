import React from 'react';
import styled from 'styled-components';

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
  padding-top: 0;
`;

const LogoContainer = styled.div`
  margin: 18px;
  padding: 18px;
  user-select: none;
`;

const TextContainer = styled.div`
  max-width: 800px;
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0 30px; // Increased padding for mobile
    width: 100%; // Ensure full width on mobile
    max-width: 100%; // Allow container to take full width on mobile
  }
`;

const SmallText = styled.p`
  font-size: 0.8rem;
  line-height: 1.4;
  color: white;
  text-align: left;
  margin-bottom: 10px;
`;

const NinjaTraderSection: React.FC = () => {
  return (
    <SectionContainer id="ninjatrader-logo">
      <LogoContainer>
        <a href="https://ninjatrader.com" target="_blank" rel="noopener noreferrer">
          <img 
            src="/assets/NinjaTrader_Wordmark_color_RGB.png" 
            alt="NinjaTrader Logo" 
            style={{ height: '30px', width: 'auto' }} 
          />
        </a>
      </LogoContainer>
      <TextContainer>
        <SmallText>
          <strong>Risk Disclosure:</strong> Futures and forex trading contains substantial risk and is not for every investor. An investor could potentially lose all or more than the initial investment. Risk capital is money that can be lost without jeopardizing one's financial security or lifestyle. Only risk capital should be used for trading and only those with sufficient risk capital should consider trading. Past performance is not necessarily indicative of future results.
        </SmallText>
        <SmallText>
          <strong>NinjaTrader Disclaimer:</strong> NinjaTrader® is a registered trademark of NinjaTrader Group, LLC. No NinjaTrader company has any affiliation with the owner, developer, or provider of the products or services described herein, or any interest, ownership, or otherwise, in any such product or service, or endorses, recommends, or approves any such product or service.
        </SmallText>
      </TextContainer>
    </SectionContainer>
  );
};

export default NinjaTraderSection;
