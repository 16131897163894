import React, { useState } from 'react';
import styled from 'styled-components';
import TermsOfService from './TermsOfService'; // Import your TermsOfService component

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: var(--color-card-bg);
  color: var(--color-text);
  padding: 2rem;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;

  h2 {
    margin-bottom: 1rem;
    color: var(--color-primary);
  }

  p {
    line-height: 1.6;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; /* Center the checkbox and label */
  margin: 1.5rem 0;
`;

const Checkbox = styled.input`
  display: none; /* Hide the default checkbox */
`;

const CustomCheckbox = styled.div<{ checked: boolean }>`
  width: 20px;
  height: 20px;
  border: 2px solid var(--color-primary);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  cursor: pointer;
  background-color: ${({ checked }) => (checked ? 'var(--color-primary)' : 'transparent')};

  &:hover {
    border-color: var(--color-secondary);
  }

  &::after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 2px;
    display: ${({ checked }) => (checked ? 'block' : 'none')};
  }
`;

const CheckboxLabel = styled.label`
  color: var(--color-text);
  font-size: 0.9rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between; /* Space between buttons */
  margin-top: 1rem;
`;

const Button = styled.button`
  background-color: var(--color-primary);
  color: white;
  padding: 0.75rem 0.75rem; /* Reduced horizontal padding */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex: 1; /* Allow buttons to take equal space */
  margin: 0 0.5rem; /* Add margin to prevent touching */

  &:hover {
    background-color: var(--color-secondary);
  }

  &:disabled {
    background-color: var(--color-border);
    cursor: not-allowed;
  }
`;

const DeclineButton = styled(Button)`
  background-color: transparent;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);

  &:hover {
    background-color: var(--color-primary);
    color: white;
  }
`;

interface TermsAcceptanceProps {
  userId: number;
  version: string;
  onAccepted: () => void;
  onExit: () => void; // Add onExit prop
}

const TermsAcceptance: React.FC<TermsAcceptanceProps> = ({ userId, version, onAccepted, onExit }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleAgreeClick = async () => {
    if (isChecked) {
      try {
        const response = await fetch('https://riskmaster.pro/accept_terms_proxy.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: userId,  // This should be loggedInUserId from your session
            version: version,
          }),
        });

        const data = await response.json();

        if (data.status === 'success') {
          onAccepted();
        } else {
          console.error('Error accepting terms:', data.message);
        }
      } catch (error) {
        console.error('Request failed:', error);
      }
    }
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <h2>Terms of Service</h2>
        <div style={{ maxHeight: '400px', overflowY: 'auto', border: '1px solid var(--color-border)', padding: '1rem' }}>
          <TermsOfService closeTermsOfService={() => {}} openPrivacyPolicy={() => {}} /> {/* Fixing the missing props error */}
        </div>

        <CheckboxContainer onClick={handleCheckboxChange}>
          <CustomCheckbox checked={isChecked} />
          <CheckboxLabel>I have read and agree to the Terms of Service</CheckboxLabel>
          <Checkbox type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
        </CheckboxContainer>

        <ButtonContainer>
          <DeclineButton onClick={onExit}>Decline</DeclineButton>
          <Button onClick={handleAgreeClick} disabled={!isChecked}>Agree</Button>
        </ButtonContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default TermsAcceptance;
