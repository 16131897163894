// src/components/BuyNowButton.tsx

import React from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';

const CTAButton = styled.button<{ isVisible: boolean }>`
  user-select: none;
  background-color: #FF4500;
  color: white;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
  border: none;
  display: inline-block;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  box-shadow: 0 5px 15px rgba(255, 69, 0, 0.3);

  &:hover {
    background-color: #FF6347;
    transform: scale(1.05);
    box-shadow: 0 7px 20px rgba(255, 99, 71, 0.4);
  }
`;

const BuyNowButton: React.FC = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const handleScrollToPricing = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    // Trigger Meta Pixel event: Buy Now Clicked
    if (window.fbq) {
      window.fbq('track', 'BuyNowClick');
    }

    const targetElement = document.querySelector("#pricing");
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <CTAButton ref={ref} onClick={handleScrollToPricing} isVisible={inView}>
      Buy Now
    </CTAButton>
  );
};

export default BuyNowButton;
