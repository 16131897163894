import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; // Import Link

const FAQContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  padding-bottom: 0rem; // Increased bottom padding
`;

const Title = styled.h2`
  color: white;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  user-select: none;
`;

const FAQItem = styled.div`
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--color-border);
  user-select: none;
`;

const Question = styled.button`
  user-select: none;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  padding: 1rem 0;
  color: var(--color-text);
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: var(--color-primary);
  }
`;

const Answer = styled.div<{ isOpen: boolean }>`
  padding: ${props => (props.isOpen ? '1rem 0' : '0')};
  max-height: ${props => (props.isOpen ? '1000px' : '0')};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
`;

const Icon = styled.span`
  font-size: 1.5rem;
`;

const faqData = [
  {
    question: "What is Risk Master?",
    answer: "Risk Master is a powerful risk management tool designed for NinjaTrader 8 users. It helps traders manage their risk by setting limits on trades, losses, and more."
  },
  {
    question: "How does Risk Master work?",
    answer: "Risk Master integrates with your NinjaTrader 8 platform and allows you to set various parameters such as maximum contracts, daily loss or win limits, and trading time restrictions. It then enforces these rules automatically during your trading sessions."
  },
  {
    question: "Is Risk Master compatible with my trading strategy?",
    answer: "Risk Master is designed to work with a wide range of trading strategies. It focuses on risk management rather than dictating specific trading methods, making it adaptable to most trading approaches."
  },
  {
    question: "How do I install Risk Master?",
    answer: (
      <>
        Risk Master can be easily installed as an add-on to your NinjaTrader 8 platform. Detailed installation instructions are provided upon purchase or in our <Link to="/help-center" style={{ color: 'var(--color-primary)', textDecoration: 'underline' }}>Help Center</Link>.
      </>
    )
  },
  {
    question: "Can I customize the risk parameters?",
    answer: "Yes, Risk Master offers full customization of all risk parameters. You can adjust settings like maximum contracts, daily loss or win limits, and trading hours to suit your individual trading style and risk tolerance."
  },
  {
    question: "Can I use Risk Master on multiple trading accounts?",
    answer: "Yes, Risk Master is designed to be used with multiple trading accounts. You can easily switch between accounts and set different risk parameters for each account."
  },
  {
    question: "How often is Risk Master updated?",
    answer: "We regularly update Risk Master to ensure compatibility with the latest version of NinjaTrader 8 and to add new features. Updates are typically released on a monthly basis, with critical updates pushed out as needed."
  },
  {
    question: "Can Risk Master help prevent emotional trading?",
    answer: "Absolutely. By setting predefined risk parameters, Risk Master helps remove emotion from trading decisions. It can automatically enforce your trading rules, preventing impulsive trades that might violate your risk management strategy."
  },
  {
    question: "Is customer support available if I have issues or questions?",
    answer: "We provide dedicated customer support for all Risk Master users. This includes email support, and an extensive knowledge base."
  },
  {
    question: "Can Risk Master integrate with other trading tools or platforms?",
    answer: "While Risk Master is specifically designed for NinjaTrader 8, it can indirectly work alongside other trading tools you might use. However, direct integration with other platforms is not currently supported."
  }
];

const FAQ: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleQuestion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <FAQContainer>
      <Title>Frequently Asked Questions</Title>
      {faqData.map((item, index) => (
        <FAQItem key={index}>
          <Question onClick={() => toggleQuestion(index)}>
            {item.question}
            <Icon>{openIndex === index ? '-' : '+'}</Icon>
          </Question>
          <Answer isOpen={openIndex === index}>{item.answer}</Answer>
        </FAQItem>
      ))}
    </FAQContainer>
  );
};

export default FAQ;
