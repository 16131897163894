import React from 'react';
import styled from 'styled-components';

const TermsContainer = styled.div`
  user-select: text;
  background-color: transparent;
  color: var(--color-text);
  padding: 1rem;
`;

const Title = styled.h2`
  color: var(--color-primary);
  margin-bottom: 1rem;
`;

const SectionTitle = styled.h3`
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--color-secondary);
`;

const Paragraph = styled.p`
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const List = styled.ul`
  margin-top: 0.5rem;
  padding-left: 1.5rem;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;

const LinkTo = styled.a`
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: var(--color-secondary);
  }
`;

interface TermsOfServiceProps {
  closeTermsOfService: () => void;
  openPrivacyPolicy: () => void;
}

const TermsOfService: React.FC<TermsOfServiceProps> = ({ closeTermsOfService, openPrivacyPolicy }) => {
  const handlePrivacyPolicyClick = () => {
    closeTermsOfService();
    openPrivacyPolicy();
  };

  return (
    <TermsContainer>
      <Title>Terms of Service</Title>

      <SectionTitle>1. Acceptance of Terms</SectionTitle>
      <Paragraph>
        Welcome to M.S Venture LLC ("Company," "we," "us," or "our"). These Terms of Service ("Terms") govern your access to and use of our RiskMaster software and website ("Software"). By using the Software, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, do not access or use the Software.
      </Paragraph>

      <SectionTitle>2. License and Use</SectionTitle>
      <Paragraph>
        2.1 License Grant: Upon purchase, we grant you a non-exclusive, non-transferable, limited license to use the Software for personal or professional use, subject to the terms specified herein.
      </Paragraph>
      <Paragraph>
        2.2 License Restrictions:
      </Paragraph>
      <List>
        <ListItem>The license permits installation and use on up to three devices concurrently. Usage beyond three devices requires deactivation of one or more previously registered devices.</ListItem>
        <ListItem>Users may not copy, modify, distribute, sell, or lease any part of the Software, nor may they reverse-engineer or attempt to extract the source code.</ListItem>
      </List>
      <Paragraph>
        2.3 Deactivation and Restrictions:
      </Paragraph>
      <List>
        <ListItem>Use of the Software is restricted to active license holders. Upon license deactivation, users must immediately cease using the Software.</ListItem>
        <ListItem>Attempts to exploit the Software or bypass device restrictions may result in termination of the license without notice.</ListItem>
      </List>

      <SectionTitle>3. Software Usage and Limitations</SectionTitle>
      <Paragraph>
        3.1 Risk and Responsibility:
      </Paragraph>
      <Paragraph>
        Users acknowledge that trading and investing in financial instruments carry significant risk, including the potential for substantial losses. The Company is not responsible for any losses incurred by users. The Software is provided for informational and educational purposes only and should not be considered financial advice.
      </Paragraph>
      <Paragraph>
        3.2 Hypothetical Performance Disclaimer:
      </Paragraph>
      <Paragraph>
        All performance data provided by the Software are hypothetical and do not guarantee future results. Past performance is not an indicator of future performance.
      </Paragraph>
      <Paragraph>
        3.3 No Guarantee of Success:
      </Paragraph>
      <Paragraph>
        M.S Venture LLC does not guarantee the success of any trading strategies or investments made using the Software. Users are advised to consult with a qualified financial professional before making any investment decisions.
      </Paragraph>

      <SectionTitle>4. Liability Limitations</SectionTitle>
      <Paragraph>
        4.1 Disclaimer of Warranties:
      </Paragraph>
      <Paragraph>
        The Software is provided "as is" and "as available" without any warranties, either express or implied. The Company disclaims all warranties, including but not limited to merchantability, fitness for a particular purpose, and non-infringement.
      </Paragraph>
      <Paragraph>
        4.2 Limitation of Liability:
      </Paragraph>
      <Paragraph>
        To the maximum extent permitted by law, M.S Venture LLC shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or related to your use of the Software. This includes any loss of profits, trading losses, or damages arising from the failure of the Software.
      </Paragraph>
      <Paragraph>
        4.3 Indemnification:
      </Paragraph>
      <Paragraph>
        You agree to indemnify and hold harmless M.S Venture LLC from any claims, losses, or damages, including legal fees, arising out of your use of the Software, your violation of these Terms, or your violation of any third-party rights.
      </Paragraph>

      <SectionTitle>5. User Obligations</SectionTitle>
      <Paragraph>
        5.1 Compliance with Applicable Laws: Users agree to use the Software in compliance with all applicable local, state, national, and international laws.
      </Paragraph>
      <Paragraph>
        5.2 Prohibited Conduct: Users may not use the Software for any illegal activities, including but not limited to fraud, money laundering, or manipulation of financial markets. Users may not use the Software to attempt to circumvent or bypass the one-device restriction or any other security measures implemented by the Company.
      </Paragraph>

      <SectionTitle>6. Termination of License</SectionTitle>
      <Paragraph>
        6.1 Termination by User: You may terminate your license at any time by discontinuing the use of the Software and uninstalling it from all registered devices.
      </Paragraph>
      <Paragraph>
        6.2 Termination by the Company: We reserve the right to terminate or suspend your license without notice if you breach these Terms, engage in fraudulent activities, or otherwise misuse the Software.
      </Paragraph>

      <SectionTitle>7. Intellectual Property Rights</SectionTitle>
      <Paragraph>
        7.1 Ownership: All intellectual property rights, including copyrights, trademarks, and patents, in and to the Software are owned by M.S Venture LLC. Nothing in these Terms grants you any rights in the Software other than the limited license granted herein.
      </Paragraph>
      <Paragraph>
        7.2 Copyright Protection: You agree not to copy, reproduce, or distribute any portion of the Software without the Company's express written consent.
      </Paragraph>

      <SectionTitle>8. Privacy and Data Security</SectionTitle>
      <Paragraph>
        8.1 Privacy Policy: Our <LinkTo onClick={handlePrivacyPolicyClick}>Privacy Policy</LinkTo> outlines how we collect, use, and protect your personal information. By using the Software, you agree to the terms of our Privacy Policy.
      </Paragraph>
      <Paragraph>
        8.2 Data Transmission: While we implement measures to secure data transmission, we cannot guarantee the complete security of any data sent over the internet. You acknowledge that you transmit information at your own risk.
      </Paragraph>

      <SectionTitle>9. Changes to the Terms of Service</SectionTitle>
      <Paragraph>
        We reserve the right to update these Terms from time to time. The updated Terms will be posted on our website, and users will be notified via email or on the Software. Continued use of the Software constitutes acceptance of the updated Terms.
      </Paragraph>

      <SectionTitle>10. Governing Law and Dispute Resolution</SectionTitle>
      <Paragraph>
        10.1 Governing Law: These Terms shall be governed by and construed in accordance with the laws of the State of Delaware, without regard to its conflict of law principles.
      </Paragraph>
      <Paragraph>
        10.2 Dispute Resolution: Any disputes arising out of or related to these Terms shall be resolved through arbitration in Wilmington, Delaware, in accordance with the rules of the American Arbitration Association.
      </Paragraph>

      <SectionTitle>11. Contact Information</SectionTitle>
      <Paragraph>
        If you have any questions or concerns about these Terms of Service, you can contact us at:
      </Paragraph>
      <Paragraph>
        Email: <a href="mailto:info@riskmaster.pro">info@riskmaster.pro</a>
      </Paragraph>
    </TermsContainer>
  );
};

export default TermsOfService;
