import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import BackgroundContainer from './BackgroundContainer';
import StepsContainer from './StepsContainer';
import PopUpObject from './PopUpObject';
import StartOverlay from './StartOverlay';
import EndOverlay from './EndOverlay';
import './../../styles/guide.css';
import NavigationArrow from './NavigationArrow';

// Add this new styled component
const GuideTitle = styled.h2`
  color: white;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: bold;
  user-select: none;
`;

interface GuideProps {
  hideContainer?: boolean;
  startAnimation?: boolean;
  shouldStartGuide: boolean;
}

const Guide: React.FC<GuideProps> = ({ hideContainer = false, startAnimation = false, shouldStartGuide }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [subStep, setSubStep] = useState(0);
  const [showPopUp, setShowPopUp] = useState(false);
  const [isChangingBackground, setIsChangingBackground] = useState(false);
  const [overlayHidden, setOverlayHidden] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showEndOverlay, setShowEndOverlay] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [showArrows, setShowArrows] = useState(false);

  const stepsData = [
    {
      image: '/assets/guide/1.png',
      title: "Create Profile", // Add a title for each step
      subSteps: [
        {
          popUp: { x: 0.7, y: 0.25, text: "Press here to create a profile", direction: "bottom-right" as const },
        }
      ]
    },
    {
      image: '/assets/guide/2.png',
      title: "Save Profile",
      subSteps: [
        {
          popUp: { x: 0.775, y: 0.7, text: "Save the profile", direction: "bottom-left" as const },
        },
      ]
    },
    {
      image: '/assets/guide/3.png',
      title: "Choose Profile",
      subSteps: [
        {
          popUp: { x: 0.275, y: 0.3, text: "Choose the profile you have just created", direction: "bottom-right" as const },
        },
      ]
    },
    {
      image: '/assets/guide/4.png',
      title: "Profile Details",
      subSteps: [
        {
          popUp: { x: 0.7, y: 0.55, text: "You can see the profile details here.", direction: "top-left" as const },
        },
        {
          popUp: { x: 0.75, y: 0.65, text: "Assign the profile to an account", direction: "middle-right" as const },
        },
      ]
    },
    {
      image: '/assets/guide/5.png',
      title: "Assign Profile",
      subSteps: [
        {
          popUp: { x: 0.5, y: 0.55, text: "Choose the account and Press Assign", direction: "middle-right" as const },
        },
      ]
    },
    {
      image: '/assets/guide/6.png',
      title: "Profile Assignment",
      subSteps: [
        {
          popUp: { x: 0.45, y: 0.5, text: "The profile is now assigned to the account.\nYou can see the Active profile, Tomorow's profile, and the status of the account in the list.", direction: "middle-right" as const },
        },
        {
          popUp: { x: 0.6, y: 0.6, text: "Lets assign another profile to the account.", direction: "top-left" as const },
        },
      ]
    },
    {
      image: '/assets/guide/7.png',
      title: "Assign Another Profile",
      subSteps: [
        {
          popUp: { x: 0.5, y: 0.65, text: "Choose the account from the list and press Assign Profile.", direction: "middle-right" as const },
        },
      ]
    },
    {
      image: '/assets/guide/8.png',
      title: "Example2 Assignment",
      subSteps: [
        {
          popUp: { x: 0.50, y: 0.55, text: "Click on Example2, then Assign", direction: "middle-right" as const },
        },
      ]
    },
    {
      image: '/assets/guide/9.png',
      title: "Profile Assignment Limit",
      subSteps: [
        {
          popUp: { x: 0.45, y: 0.5, text: "The profile 'Example2' was only assigned to Tommorow's profile, this is a feature meant to prevent you from assigning a new active profile to an account on the same day.", direction: "middle-right" as const },
        },
      ]
    },
    {
      image: '/assets/guide/10.png',
      title: "Settings Menu",
      subSteps: [
        {
          popUp: { x: 0.6, y: 0.40, text: "In the settings menu, you can manage several important aspects of the RiskMaster addon, including viewing your license status, assigning your email, and designating a trusted third-party email.", direction: "middle-right" as const },
        },
        {
          popUp: { x: 0.6, y: 0.40, text: "This feature is designed to help you maintain discipline in your trading strategy by imposing restrictions when you cross predefined thresholds, such as daily loss or win limits.", direction: "middle-right" as const },
        },
        {
          popUp: { x: 0.6, y: 0.40, text: "If you wish to remove these restrictions, the only way to do so is by involving a trusted third party, such as a friend or family member.", direction: "middle-right" as const },
        },
        {
          popUp: { x: 0.6, y: 0.40, text: "When you request to remove the block, an email is sent to the designated third party, who will then need to provide a password to lift the restrictions.", direction: "middle-right" as const },
        },
        {
          popUp: { x: 0.6, y: 0.40, text: "This process is intended to prevent impulsive decisions, especially during significant losses, and helps protect you from potential overtrading and further financial risk.", direction: "middle-right" as const },
        },
        {
          popUp: { x: 0.6, y: 0.40, text: "By involving a trusted individual, you add an extra layer of accountability and caution, ensuring that any decision to override the block is made thoughtfully and with the guidance of someone you trust.", direction: "middle-right" as const },
        },
      ]
    },
  ];

  const totalSubSteps = useMemo(() => {
    return stepsData.reduce((total, step) => total + step.subSteps.length, 0);
  }, [stepsData]);

  const currentSubStepIndex = useMemo(() => {
    return stepsData.slice(0, currentStep).reduce((total, step) => total + step.subSteps.length, 0) + subStep;
  }, [currentStep, subStep, stepsData]);

  const currentPopUpData = useMemo(() => {
    if (currentStep < stepsData.length && subStep < stepsData[currentStep]?.subSteps?.length) {
      return stepsData[currentStep].subSteps[subStep].popUp;
    }
    return null;
  }, [currentStep, subStep, stepsData]);

  const handleStartGuide = () => {
    setOverlayHidden(true);
    setTimeout(() => {
      setTimeout(() => setShowPopUp(true), 200);
    }, 300);
  };

  const handleNextSubStep = () => {
    if (isTransitioning) return;

    setShowPopUp(false);
    
    setTimeout(() => {
      if (currentStep < stepsData.length) {
        if (subStep < stepsData[currentStep].subSteps.length - 1) {
          // If there are more sub-steps in the current step, move to the next sub-step
          setSubStep(subStep + 1);
          showPopUpOnly();
        } else if (currentStep < stepsData.length - 1) {
          // If we're at the last sub-step of the current step, move to the next step
          handleStepChange(currentStep + 1);
        } else {
          // If we're at the last sub-step of the last step, show the end overlay
          setShowEndOverlay(true);
        }
      }
    }, 300);
  };

  const handlePreviousSubStep = () => {
    if (isTransitioning) return;

    setShowPopUp(false);
    
    setTimeout(() => {
      if (currentStep === 0 && subStep === 0) {
        // If we're at the first step and first substep, restart the guide
        handleRestart();
      } else if (subStep > 0) {
        // If we're not at the first sub-step, move to the previous sub-step
        setSubStep(subStep - 1);
        showPopUpOnly();
      } else if (currentStep > 0) {
        // If we're at the first sub-step of the current step, move to the last sub-step of the previous step
        const previousStep = currentStep - 1;
        const lastSubStepIndex = stepsData[previousStep].subSteps.length - 1;
        handleStepChange(previousStep, true);
        setSubStep(lastSubStepIndex);
      }
    }, 300);
  };

  const handleStepChange = (step: number, goToLastSubStep: boolean = false) => {
    setIsTransitioning(true);
    
    // 1. Fade out pop-up
    setShowPopUp(false);

    // Wait for pop-up to fade out
    setTimeout(() => {
      // 2. Start background transition
      setIsChangingBackground(true);
      setCurrentStep(step);
      setSubStep(goToLastSubStep ? stepsData[step].subSteps.length - 1 : 0);

      // Wait for background transition
      setTimeout(() => {
        setIsChangingBackground(false);

        // 3. Fade in new pop-up
        setTimeout(() => {
          setShowPopUp(true);
          setIsTransitioning(false);
        }, 200);
      }, 500); // This should match the CSS transition duration for background
    }, 300); // This should match the CSS transition duration for pop-up
  };

  const showPopUpOnly = () => {
    setTimeout(() => setShowPopUp(true), 250);
  };

  const handleHover = (hovering: boolean) => {
    setIsHovered(hovering);
  };

  const handleRestart = () => {
    setShowEndOverlay(false);
    setCurrentStep(0);
    setSubStep(0);
    setIsChangingBackground(true);
    setTimeout(() => {
      setIsChangingBackground(false);
      showPopUpOnly();
    }, 300);
  };

  useEffect(() => {
    if (shouldStartGuide && !overlayHidden) {
      handleStartGuide();
    }
  }, [shouldStartGuide]);

  useEffect(() => {
    if (overlayHidden) {
      const timer = setTimeout(() => setShowPopUp(true), 1000);
      return () => clearTimeout(timer);
    }
  }, [currentStep, subStep, overlayHidden]);

  if (hideContainer) {
    return null;
  }

  return (
    <>
      <GuideTitle>Interactive Guide</GuideTitle>
      <div 
        className="guide-container"
        onMouseEnter={() => setShowArrows(true)}
        onMouseLeave={() => setShowArrows(false)}
      >
        <BackgroundContainer 
          image={stepsData[currentStep].image} 
          isChanging={isChangingBackground}
        />
        <StartOverlay onStart={handleStartGuide} hidden={overlayHidden} />
        {overlayHidden && !showEndOverlay && (
          <>
            <NavigationArrow 
              direction="left" 
              onClick={handlePreviousSubStep}
              style={{ opacity: showArrows ? 1 : 0 }}
            />
            <NavigationArrow 
              direction="right" 
              onClick={handleNextSubStep}
              style={{ opacity: showArrows ? 1 : 0 }}
            />
            {currentPopUpData && (
              <PopUpObject
                x={currentPopUpData.x}
                y={currentPopUpData.y}
                text={currentPopUpData.text}
                direction={currentPopUpData.direction}
                onClick={handleNextSubStep}
                showPopUp={showPopUp}
                currentSubStepIndex={currentSubStepIndex}
                totalSubSteps={totalSubSteps}
                isHovered={isHovered}
                onHover={handleHover}
              />
            )}
            <StepsContainer
              currentStep={currentStep}
              totalSteps={stepsData.length}
              onStepChange={handleStepChange}
              stepTitles={stepsData.map(step => step.title)} // Pass step titles to StepsContainer
            />
          </>
        )}
        <EndOverlay onRestart={handleRestart} visible={showEndOverlay} />
      </div>
    </>
  );
};

export default Guide;