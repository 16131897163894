import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

// Styled components
const PageBackground = styled.div`
  background-color: var(--color-section-2-bg); /* Use section 2 background */
  padding: 1rem 0 4rem; /* Increased bottom padding */
`;

const FeaturesContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  color: white; /* Changed to white */
  text-align: center;
  margin-bottom: 3rem; /* Increased margin to pad from feature cards */
  font-size: 2.5rem;
  user-select: none;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(312.5px, 1fr)); // 25% larger than original 250px
  gap: 2.5rem;
`;

const cardAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-7px); }
  100% { transform: translateY(0); }
`;

const FeatureCard = styled.div`
  user-select: none;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  height: 250px; // 25% larger

  &:hover {
    transform: translateY(-7px);
  }

  &.animate {
    animation: ${cardAnimation} 2s ease-in-out;
  }
`;

const FeatureImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FeatureTitle = styled.h3`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white; /* Changed to white */
  padding: 0.75rem;
  margin: 0;
  text-align: center;
  font-size: 1.25rem;
  user-select: none;
  font-weight: norm;
`;

const hoverAnimation = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

const FeatureOverlay = styled.div<{ delay: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;

  ${FeatureCard}:hover & {
    opacity: 1;
  }

  &.animate {
    animation: ${hoverAnimation} 2s ease-in-out;
    animation-delay: ${props => props.delay}ms;
  }
`;

const FeatureDescription = styled.p`
  color: white;
  text-align: center;
  padding: 1.25rem;
  margin: 0;
  font-size: 1.1rem;
  user-select: none;
`;

// Feature data
const features = [
  {
    title: 'Max allowed contracts',
    description: 'Set a maximum limit on the number of contracts that can be purchased in a single trade to manage risk and prevent overexposure in any single position.',
    icon: 'contract-limit.jpg'
  },
  {
    title: 'Number of account trades allowed',
    description: 'Define a daily limit for the total number of trades on a specific account. Once this limit is reached, the account is locked for the remainder of the day to prevent excessive trading.',
    icon: 'trade-limit.jpg'
  },
  {
    title: 'Consecutive loss limit',
    description: 'Set a limit on the number of consecutive losing trades allowed. If this limit is reached, the account is locked to prevent further losses and to allow for strategic reassessment.',
    icon: 'consecutive-loss.jpg'
  },
  {
    title: 'Daily loss threshold',
    description: 'Set a maximum daily loss limit. If the total losses for the day reach this limit, all open trades are closed, and the account is locked to prevent further financial damage.',
    icon: 'daily-loss.jpg'
  },
  {
    title: 'Daily profit target',
    description: 'Set a daily profit goal for your account. When this profit target is achieved, all open trades are closed, and the account is locked to secure gains and prevent overtrading.',
    icon: 'profit-target.jpg'
  },
  {
    title: 'Single trade loss limit',
    description: 'Define the maximum loss allowable for an individual trade. If a trade incurs losses reaching this limit, it is automatically closed to minimize further financial loss.',
    icon: 'single-trade-loss.jpg'
  },
  {
    title: 'Allowed trading days',
    description: 'Choose specific days of the week when trading is allowed on a particular account. The account will be locked on days when trading is not permitted, helping to enforce a disciplined trading schedule.',
    icon: 'trading-days.jpg'
  },
  {
    title: 'Time restriction window',
    description: 'Set specific trading hours during which trading is permitted. Outside these hours, the account is locked to prevent overtrading and to help maintain a disciplined trading routine.',
    icon: 'time-restriction.jpg'
  },
  {
    title: 'Third party risk management',
    description: 'Implement third-party risk management measures to ensure that your trading account is fully compliant with regulatory requirements.',
    icon: 'third_party_risk_management.jpg'
  }
];

// Features component
const Features: React.FC = () => {
  const featuresRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (featuresRef.current) {
        const rect = featuresRef.current.getBoundingClientRect();
        const offset = window.innerHeight * 0.3; // 30% of viewport height
        if (rect.top <= offset && rect.bottom >= offset) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Call once to check initial state
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (isVisible) {
      const cards = document.querySelectorAll('.feature-card');
      const overlays = document.querySelectorAll('.feature-overlay');
      cards.forEach((card, index) => {
        setTimeout(() => {
          card.classList.add('animate');
          overlays[index].classList.add('animate');
        }, index * 200);
      });
    }
  }, [isVisible]);

  return (
    <PageBackground>
      <FeaturesContainer ref={featuresRef}>
        <Title>Features</Title>
        <FeaturesGrid>
          {features.map((feature, index) => (
            <FeatureCard key={index} className="feature-card">
              <FeatureImage src={`/assets/${feature.icon}`} alt={feature.title} />
              <FeatureTitle>{feature.title}</FeatureTitle>
              <FeatureOverlay className="feature-overlay" delay={index * 200}>
                <FeatureDescription>{feature.description}</FeatureDescription>
              </FeatureOverlay>
            </FeatureCard>
          ))}
        </FeaturesGrid>
      </FeaturesContainer>
    </PageBackground>
  );
};

export default Features;