// src/pages/Newsletter.tsx

import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { NotificationContext } from '../contexts/NotificationContext'; // Import NotificationContext

const NewsletterContainer = styled.div`
  background-color: var(--color-section-4-bg);
  padding: 2rem 2rem;
  text-align: center;
  margin-top: -4rem;
`;

const Title = styled.h2`
  color: white;
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  color: var(--color-text);
  margin-bottom: 2rem;
`;

const Form = styled.form`
  display: flex;
  justify-content: center;
  gap: 1rem;
  max-width: 500px;
  margin: 0 auto;
  user-select: none;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Input = styled.input`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  flex-grow: 1;
  width: 100%;
`;

const SubmitButton = styled.button`
  background-color: var(--color-primary);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 150px;

  &:hover {
    background-color: var(--color-secondary);
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Newsletter: React.FC = () => {
  const { showNotification } = useContext(NotificationContext); // Access Notification Context
  const [email, setEmail] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsSubmitting(true);

    try {
      const response = await fetch('https://riskmaster.pro/add_newsletter_subscriber_proxy.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok && data.status === 'success') {
        showNotification('Thank you for subscribing! Please check your email to confirm your subscription.');
        setEmail(''); // Clear the input field
      } else if (data.message && data.message.includes('already subscribed')) {
        showNotification('You are already subscribed to our newsletter.');
      } else {
        showNotification(data.message || 'An error occurred. Please try again later.');
      }
    } catch (error) {
      showNotification('Unable to subscribe. Please try again later.');
      console.error('Newsletter Subscription Error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <NewsletterContainer>
      <Title>Stay Updated</Title>
      <Description>Subscribe to our newsletter for the latest updates and tips.</Description>
      <Form onSubmit={handleSubmit}>
        <Input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <SubmitButton type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Subscribe'}
        </SubmitButton>
      </Form>
    </NewsletterContainer>
  );
};

export default Newsletter;
