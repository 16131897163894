// src/pages/Login.tsx

import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { NotificationContext } from '../contexts/NotificationContext'; // Import NotificationContext

interface LoginProps {
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}

// Styled components for UI elements
const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--color-background);
`;

const LoginCard = styled.div`
  background-color: var(--color-card-bg);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h1`
  text-align: center;
  color: var(--color-text);
  margin-bottom: 1.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Input = styled.input`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  background-color: var(--color-input-bg);
  color: var(--color-text);
  width: 100%;
  max-width: 300px;
`;

const Button = styled.button`
  padding: 0.75rem;
  background-color: var(--color-primary);
  color: var(--color-background);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  max-width: 300px;
  margin-top: 0.5rem;

  &:hover {
    background-color: var(--color-secondary);
  }
`;

const ResendButton = styled(Button)`
  background-color: var(--color-secondary);

  &:hover {
    background-color: var(--color-primary);
  }
`;

const SignUpText = styled.p`
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
  color: var(--color-text);

  a {
    color: var(--color-primary);
    text-decoration: none;
    font-weight: bold;

    &:hover {
      color: var(--color-secondary);
      text-decoration: underline;
    }
  }
`;

const Login: React.FC<LoginProps> = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const { showNotification } = useContext(NotificationContext); // Access Notification Context
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showResend, setShowResend] = useState<boolean>(false);

  // Meta Pixel: Track Page View on Login Page Load
  useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'ViewContent', { page: 'Login' });
    }
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    // Meta Pixel: Track Login Attempt
    if (window.fbq) {
      window.fbq('track', 'LoginAttempt');
    }

    try {
      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);

      const response = await axios.post(
        'https://riskmaster.pro/login_proxy.php',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      const resData = response.data;
      if (resData.status === 'success') {
        localStorage.setItem('token', resData.token);
        setIsLoggedIn(true);
        showNotification('Logged in successfully.');
        navigate('/');
      } else {
        showNotification(resData.message || 'Login failed.');
        if (resData.message && resData.message.includes('not confirmed')) {
          setShowResend(true);
        }
      }
    } catch (error) {
      showNotification('An error occurred during login. Please try again.');
      console.error(error);
    }
  };

  const handleResendConfirmation = async () => {
    // Meta Pixel: Track Resend Confirmation
    if (window.fbq) {
      window.fbq('track', 'ResendConfirmation');
    }

    try {
      const formData = new FormData();
      formData.append('email', email);

      const response = await axios.post(
        'https://riskmaster.pro/resend_confirmation_proxy.php',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      const resData = response.data;
      if (resData.status === 'success') {
        showNotification('Confirmation email resent. Please check your inbox.');
      } else {
        showNotification(resData.message || 'Failed to resend confirmation email.');
      }
    } catch (error) {
      showNotification('An error occurred while resending the confirmation email. Please try again.');
      console.error(error);
    }
  };

  return (
    <LoginContainer>
      <LoginCard>
        <Title>Login to RiskMaster</Title>
        <Form onSubmit={handleSubmit}>
          <Input
            type="email"
            placeholder="Email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit">Log In</Button>
          {showResend && (
            <ResendButton type="button" onClick={handleResendConfirmation}>
              Resend Confirmation Email
            </ResendButton>
          )}
        </Form>
        <SignUpText>
          Don't have an account? <Link to="/signup">Sign Up</Link>
        </SignUpText>
      </LoginCard>
    </LoginContainer>
  );
};

export default Login;
