import React from 'react';
import './../../styles/guide.css';

interface StartOverlayProps {
  onStart: () => void;
  hidden: boolean;
}

const StartOverlay: React.FC<StartOverlayProps> = ({ onStart, hidden }) => {
  return (
    <div className={`start-overlay ${hidden ? 'hidden' : ''}`} onClick={onStart}>
      {/* The entire overlay is clickable */}
    </div>
  );
};

export default StartOverlay;
