import React from 'react';
import styled from 'styled-components';

const ModalTitle = styled.h2`
  color: var(--color-primary);
  margin-bottom: 1rem;
  text-align: center; /* Center the title */
  user-select: text; /* Enable text selection */
`;

const ModalSection = styled.section`
  margin-bottom: 1.5rem;
  text-align: center; /* Center the text */
  user-select: text; /* Enable text selection */
`;

const ModalList = styled.ul`
  padding-left: 0;
  list-style-type: none; /* Remove bullet points */
  text-align: center; /* Center the list text */
  user-select: text; /* Enable text selection */

  li {
    margin-bottom: 0.5rem;
  }
`;

const ContactLink = styled.a`
  color: var(--color-primary);
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  user-select: text; /* Enable text selection */
  &:hover {
    text-decoration: underline;
    color: var(--color-secondary);
  }
`;

const PrivacyPolicy: React.FC = () => {
  const handleContactClick = () => {
    window.location.href = 'mailto:info@riskmaster.pro'; // Replace with your actual contact email
  };

  return (
    <>
      <ModalTitle>Privacy Policy</ModalTitle>
      
      <ModalSection>
        <p><strong>Introduction</strong></p>
        <p>M.S Venture LLC ("Company," "we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our software extension for NinjaTrader ("Software"). Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the site or use the Software.</p>
      </ModalSection>

      <ModalSection>
        <p><strong>Information We Collect</strong></p>
        <p><strong>Personal Information:</strong> We may collect personal information that you voluntarily provide to us when you register on the website, make a purchase, participate in the affiliate program, or send a support ticket. This information may include:</p>
        <ModalList>
          <li>Email address</li>
          <li>Login credentials</li>
          <li>Affiliate program coupon codes</li>
          <li>Subscription type and details</li>
          <li>Payment information (e.g., credit card number, expiration date, and billing address)</li>
          <li>Any other information you choose to provide to us</li>
        </ModalList>
        <p><strong>Usage Data:</strong> When you use the Software, it automatically sends logs of your actions to our support team for debugging and analysis purposes. This includes all actions related to the Software's usage, such as:</p>
        <ModalList>
          <li>Software version and configuration</li>
          <li>Operating system and device information</li>
          <li>Error logs and crash reports</li>
          <li>Usage patterns and statistics</li>
        </ModalList>
        <p><strong>Cookies and Tracking Technologies:</strong> We use cookies and other tracking technologies to collect information about your interactions with our website and Software. This information may include:</p>
        <ModalList>
          <li>Browser type and version</li>
          <li>Device type and operating system</li>
          <li>IP address and location</li>
          <li>Referring URL and pages visited</li>
          <li>Time spent on our website and Software</li>
        </ModalList>
      </ModalSection>

      <ModalSection>
        <p><strong>Use of Your Information</strong></p>
        <p>We use the information we collect in the following ways:</p>
        <ModalList>
          <li>To create and manage your account.</li>
          <li>To process your transactions through our third-party payment processor, Agile.net.</li>
          <li>To track affiliate program usage.</li>
          <li>To provide customer support and respond to your inquiries.</li>
          <li>To send you updates, newsletters, and marketing communications.</li>
          <li>To analyze and improve our website and Software.</li>
          <li>To prevent and detect fraud, abuse, and other malicious activities.</li>
        </ModalList>
      </ModalSection>

      <ModalSection>
        <p><strong>Disclosure of Your Information</strong></p>
        <p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>
        <ModalList>
          <li><strong>By Law or to Protect Rights:</strong> If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.</li>
          <li><strong>Third-Party Service Providers:</strong> We may share your information with third parties that perform services for us or on our behalf, such as payment processing through Agile.net, email marketing services, and customer support software.</li>
          <li><strong>Affiliates:</strong> We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy.</li>
          <li><strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
          <li><strong>With Your Consent:</strong> We may share your information with third parties if you have given us your consent to do so.</li>
        </ModalList>
      </ModalSection>

      <ModalSection>
        <p><strong>Data Security</strong></p>
        <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other types of misuse.</p>
      </ModalSection>

      <ModalSection>
        <p><strong>Age Restriction</strong></p>
        <p>The Company does not knowingly collect information from children under the age of 18. If we learn that we have collected information from a child under age 18, we will delete that information as quickly as possible. If you believe that we might have any information from or about a child under 18, please <ContactLink onClick={handleContactClick}>contact us</ContactLink>.</p>
      </ModalSection>

      <ModalSection>
        <p><strong>Your Rights</strong></p>
        <p>You have the following rights with respect to your personal information:</p>
        <ModalList>
          <li><strong>Right to Access:</strong> You have the right to access and review your personal information.</li>
          <li><strong>Right to Correction:</strong> You have the right to correct any inaccuracies in your personal information.</li>
          <li><strong>Right to Deletion:</strong> You have the right to request the deletion of your personal information.</li>
          <li><strong>Right to Restrict Processing:</strong> You have the right to restrict the processing of your personal information.</li>
          <li><strong>Right to Data Portability:</strong> You have the right to request a copy of your personal information in a machine-readable format.</li>
        </ModalList>
      </ModalSection>

      <ModalSection>
        <p><strong>Changes to This Privacy Policy</strong></p>
        <p>We may update this Privacy Policy from time to time. If we make any changes to this Privacy Policy, we will post the updated version on our website and notify you by email or through a notification on our website.</p>
      </ModalSection>

      <ModalSection>
        <ContactLink onClick={handleContactClick}>Contact Us</ContactLink>
      </ModalSection>
    </>
  );
};

export default PrivacyPolicy;
