// BackgroundContainer.tsx

import React, { useState, useEffect } from 'react';
import '../../styles/guide.css';

interface BackgroundContainerProps {
  image: string;
  isChanging: boolean;
}

const BackgroundContainer: React.FC<BackgroundContainerProps> = ({ image, isChanging }) => {
  const [currentImage, setCurrentImage] = useState(image);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (image !== currentImage) {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentImage(image);
        setIsTransitioning(false);
      }, 500); // This should match the CSS transition duration
    }
  }, [image]);

  return (
    <div className="background-container-wrapper">
      <div
        className={`background-container ${isTransitioning ? 'fade-out' : 'fade-in'}`}
        style={{ backgroundImage: `url(${currentImage})` }}
      />
    </div>
  );
};

export default BackgroundContainer;
