// src/pages/Pricing.tsx

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import TermsAcceptance from './TermsAcceptance';
import FreeTrialButton from '../components/FreeTrialButton';

const Container = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
  margin-bottom: 4rem;
`;

const PageBackground = styled.div`
  background-color: var(--color-section-4-bg);
  padding: 4rem 0;
`;

const Title = styled.h2`
  color: white;
  text-align: center;
  margin-bottom: 2rem;
  user-select: none;
  font-size: 2rem;
`;

const Card = styled.div`
  user-select: none;
  background-color: var(--color-card-bg);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  text-align: center;
`;

const PriceContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

const Price = styled.h2`
  font-size: 3rem;
  color: white;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  font-weight: normal;
`;

const AnnualTotal = styled.span`
  font-size: 1.5rem;
  color: var(--color-text-secondary);
`;

const PriceSubtext = styled.p`
  color: var(--color-text-secondary);
  margin-bottom: 1rem;
`;

const SubscribeButton = styled.button`
  background-color: #FF4500;
  color: white;
  padding: 1rem 2rem;
  font-size: 1.25rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #FF6347;
    transform: scale(1.05);
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

const ToggleLabel = styled.span<{ isActive: boolean }>`
  color: ${props => props.isActive ? 'var(--color-primary)' : 'var(--color-text-secondary)'};
  font-weight: ${props => props.isActive ? 'bold' : 'normal'};
  margin: 0 1rem;
`;

const ToggleSwitch = styled.label`
  user-select: none;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: var(--color-primary);
  }

  &:checked + span:before {
    transform: translateX(26px);
  }
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-input-bg);
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
`;

const Pricing: React.FC = () => {
  const [isAnnual, setIsAnnual] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const monthlyLink = 'https://dcm.secureteam.net/checkout/show/41/53?showCoupon=true';
  const annualLink = 'https://dcm.secureteam.net/checkout/show/41/54?showCoupon=true';
  const termsVersion = '1.0';

  // Fetch user data on component mount
  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await fetch('https://riskmaster.pro/fetch_user_data_proxy.php', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });

          const data = await response.json();
          if (data.status === 'success') {
            setUserData(data.user);
          } else {
            setErrorMessage(data.message);
          }
        } catch (error) {
          setErrorMessage('Failed to fetch user data');
          console.error(error);
        }
      } else {
        setUserData(null);
      }
    };

    fetchUserData();
  }, []);

  const handleToggle = () => {
    setIsAnnual(!isAnnual);
  };

  const handleSubscribeClick = async () => {
    const token = localStorage.getItem('token');

    if (window.fbq) {
      window.fbq('track', 'SubscribeClick', {
        subscriptionType: isAnnual ? 'Annual' : 'Monthly',
      });
    }

    if (!token) {
      navigate('/login');
      return;
    }

    if (userData) {
      try {
        const response = await fetch('https://riskmaster.pro/check_terms_acceptance_proxy.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: userData.id,
            version: termsVersion,
          }),
        });

        const data = await response.json();

        if (data.status === 'accepted') {
          const redirectLink = isAnnual ? annualLink : monthlyLink;
          
          if (window.fbq) {
            window.fbq('track', 'SubscriptionRedirect', {
              subscriptionType: isAnnual ? 'Annual' : 'Monthly',
            });
          }

          window.location.href = redirectLink;
        } else {
          setShowTerms(true);
        }
      } catch (error) {
        console.error('Failed to check ToS acceptance:', error);
      }
    }
  };

  const handleTermsAccepted = () => {
    setShowTerms(false);
    const redirectLink = isAnnual ? annualLink : monthlyLink;

    if (window.fbq) {
      window.fbq('track', 'SubscriptionRedirect', {
        subscriptionType: isAnnual ? 'Annual' : 'Monthly',
      });
    }

    window.location.href = redirectLink;
  };

  const handleExit = () => {
    setShowTerms(false);
  };

  return (
    <PageBackground>
      <Container>
        <Title>Pricing Plan</Title>
        <Card>
          <ToggleContainer>
            <ToggleLabel isActive={!isAnnual}>Monthly</ToggleLabel>
            <ToggleSwitch>
              <ToggleInput type="checkbox" checked={isAnnual} onChange={handleToggle} />
              <ToggleSlider />
            </ToggleSwitch>
            <ToggleLabel isActive={isAnnual}>Annual</ToggleLabel>
          </ToggleContainer>
          <PriceContainer>
            <Price>${isAnnual ? 24.99 : 29.99}</Price>
            <AnnualTotal>(${isAnnual ? (24.99 * 12).toFixed(2) : (29.99 * 12).toFixed(2)}/year)</AnnualTotal>
          </PriceContainer>
          <PriceSubtext>{isAnnual ? 'per month, billed annually' : 'per month'}</PriceSubtext>
          <ButtonContainer>
            <SubscribeButton onClick={handleSubscribeClick}>Subscribe Now</SubscribeButton>
            <FreeTrialButton />
          </ButtonContainer>

          {showTerms && userData && (
            <TermsAcceptance
              userId={userData.id}
              version={termsVersion}
              onAccepted={handleTermsAccepted}
              onExit={handleExit}
            />
          )}
        </Card>
      </Container>
    </PageBackground>
  );
};

export default Pricing;
