// src/App.tsx

import React, { useRef, useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Features from './components/Features';
import Pricing from './components/Pricing';
import FAQ from './components/FAQ';
import Newsletter from './components/Newsletter';
import Login from './pages/Login';
import Guide from './components/guide/Guide';
import NinjaTraderSection from './components/NinjaTraderSection';
import HelpCenter from './pages/HelpCenter';
import Signup from './pages/SignUp';
import Account from './pages/Account';
import ProfileEdit from './pages/ProfileEdit';
import AboutUs from './pages/AboutUs';
import Download from './pages/Download';
import './styles/main.css';
import NotificationProvider from './contexts/NotificationContext';

// Declare fbq on the window object for TypeScript
declare global {
  interface Window {
    fbq: any;
  }
}

const initializeMetaPixel = () => {
  (function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = true;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = true;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  
  window.fbq('init', '1789580281850126');
  window.fbq('track', 'PageView');
};

const App: React.FC = () => {
  const [isGuideActive, setIsGuideActive] = useState(false);
  const [activeSection, setActiveSection] = useState('home');
  const [shouldStartGuide, setShouldStartGuide] = useState(false);
  const guideRef = useRef<HTMLDivElement>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    initializeMetaPixel();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  const handleScroll = useCallback(() => {
    const sections = ['home', 'features', 'guide', 'pricing', 'faq', 'newsletter', 'help-center'];
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (windowHeight + scrollTop >= documentHeight - 100) {
      setActiveSection('newsletter');
    } else {
      const currentSection = sections.find((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });

      if (currentSection) {
        setActiveSection(currentSection);
        if (currentSection === 'guide' && !isGuideActive) {
          setIsGuideActive(true);
          setShouldStartGuide(true);
        }
      }
    }
  }, [isGuideActive]);

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <NotificationProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Layout
                activeSection={activeSection}
                handleScroll={handleScroll}
                isLoggedIn={isLoggedIn}
                onLogout={handleLogout}
              >
                <section id="home">
                  <Home />
                </section>
                <section id="features">
                  <Features />
                </section>
                <section id="guide" ref={guideRef}>
                  <Guide startAnimation={isGuideActive} shouldStartGuide={shouldStartGuide} />
                </section>
                <section id="pricing">
                  <Pricing />
                </section>
                <section id="faq">
                  <FAQ />
                </section>
                <NinjaTraderSection />
                <section id="newsletter">
                  <Newsletter />
                </section>
              </Layout>
            }
          />
          <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/account" element={<Account setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/profile-edit" element={<ProfileEdit />} />
          <Route
            path="/help-center"
            element={<HelpCenter isLoggedIn={isLoggedIn} onLogout={handleLogout} />}
          />
          <Route
            path="/about-us"
            element={<AboutUs isLoggedIn={isLoggedIn} onLogout={handleLogout} />}
          />
          <Route
            path="/download"
            element={<Download isLoggedIn={isLoggedIn} onLogout={handleLogout} />}
          />
        </Routes>
      </Router>
    </NotificationProvider>
  );
};

export default App;
