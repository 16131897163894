import React, { useMemo } from 'react';
import { PopUpDirection } from '../../types';
import '../../styles/popUpObject.css';

interface PopUpObjectProps {
  x: number;
  y: number;
  text: string;
  direction: PopUpDirection;
  onClick: () => void;
  showPopUp: boolean;
  currentSubStepIndex: number;
  totalSubSteps: number;
  isHovered: boolean;
  onHover: (hovering: boolean) => void;
}

const PopUpObject: React.FC<PopUpObjectProps> = ({ 
  x, y, text, direction, onClick, showPopUp, currentSubStepIndex, totalSubSteps, isHovered, onHover 
}) => {
  const progress = currentSubStepIndex / (totalSubSteps - 1);
  
  const glowColor = useMemo(() => {
    const r = Math.round(255 * (1 - progress));
    const g = Math.round(255 * progress);
    return `${r}, ${g}, 0`;
  }, [progress]);

  return (
    <div 
      className={`pop-up-object ${isHovered ? 'hover' : ''} ${direction} ${showPopUp ? 'fade-in' : 'fade-out'}`}
      style={{
        left: `${x * 100}%`,
        top: `${y * 100}%`,
        transform: 'translate(-50%, -50%)',
        maxWidth: '60vw',
        maxHeight: '50vh',
        '--glow-color-rgb': glowColor,
      } as React.CSSProperties}
      onClick={(e) => {
        e.preventDefault();
        onHover(false);
        onClick();
      }}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
    >
      <div className="pop-up-content">{text}</div>
    </div>
  );
};

export default PopUpObject;
