import React from 'react';
import styled from 'styled-components';

interface NavigationArrowProps {
  direction: 'left' | 'right';
  onClick: () => void;
  style?: React.CSSProperties;
}

const ArrowContainer = styled.div<{ direction: 'left' | 'right' }>`
  position: absolute;
  top: 50%;
  ${props => props.direction}: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const ArrowSVG = styled.svg`
  width: 40px;
  height: 40px;
  fill: #FF4500;
  transition: fill 0.3s ease, filter 0.3s ease;

  &:hover {
    fill: #FF6347;
    filter: drop-shadow(0 0 5px rgba(255, 69, 0, 0.7));
  }
`;

const NavigationArrow: React.FC<NavigationArrowProps> = ({ direction, onClick, style }) => {
  return (
    <ArrowContainer direction={direction} onClick={onClick} style={style}>
      <ArrowSVG viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        {direction === 'left' ? (
          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
        ) : (
          <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
        )}
      </ArrowSVG>
    </ArrowContainer>
  );
};

export default NavigationArrow;
