// src/components/MobileNavigation.tsx

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import { useNavigate, useLocation } from 'react-router-dom';

interface MobileNavigationProps {
  isLoggedIn: boolean;
  onLogout: () => void;
}

const MobileNavContainer = styled.div<{ isScrolled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.isScrolled ? '1.5rem 2rem' : '1.5rem 2rem')};
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0);
  transition: padding 0.3s ease;

  @media (min-width: 769px) {
    display: none;
  }
`;

const LogoContainer = styled.div``;

const HamburgerIcon = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  span {
    height: 2px;
    width: 25px;
    background: white;
    margin-bottom: 4px;
    border-radius: 5px;
  }
`;

const MobileNavMenu = styled.div<{ isOpen: boolean }>`
  display: none;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  padding-top: 4rem;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-in-out;
  z-index: 2000;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  z-index: 3000;
`;

const MobileNavItem = styled.div`
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  padding: 1rem;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #ff4500;
  }
`;

const MobileNavigation: React.FC<MobileNavigationProps> = ({ isLoggedIn, onLogout }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    // Meta Pixel: Track Mobile Menu Open
    if (window.fbq && !isMobileMenuOpen) {
      window.fbq('track', 'MobileMenuOpen');
    }
  };

  const handleNavigation = (path: string, label: string) => {
    setIsMobileMenuOpen(false);
    if (window.fbq) {
      window.fbq('track', 'MobileNavigate', { destination: label });
    }
    navigate(path);
  };

  const renderMobileNavItems = () => {
    const isHelpCenterPage = location.pathname === '/help-center';
    const isAboutUsPage = location.pathname === '/about-us';
    const isDownloadPage = location.pathname === '/download';

    let navItems = [];

    if (isHelpCenterPage) {
      navItems = [
        { id: 'home', label: 'Home', path: '/' },
        { id: 'about-us', label: 'About Us', path: '/about-us' },
        { id: 'download', label: 'Download', path: '/download' },
      ];
    } else if (isAboutUsPage) {
      navItems = [
        { id: 'home', label: 'Home', path: '/' },
        { id: 'help-center', label: 'Help Center', path: '/help-center' },
        { id: 'download', label: 'Download', path: '/download' },
      ];
    } else if (isDownloadPage) {
      navItems = [
        { id: 'home', label: 'Home', path: '/' },
        { id: 'help-center', label: 'Help Center', path: '/help-center' },
        { id: 'about-us', label: 'About Us', path: '/about-us' },
      ];
    } else {
      navItems = [
        { id: 'home', label: 'Home', path: '/' },
        { id: 'features', label: 'Features', path: '#features' },
        { id: 'guide', label: 'Guide', path: '#guide' },
        { id: 'pricing', label: 'Pricing', path: '#pricing' },
        { id: 'faq', label: 'FAQ', path: '#faq' },
        { id: 'newsletter', label: 'Newsletter', path: '#newsletter' },
        { id: 'about-us', label: 'About Us', path: '/about-us' },
        { id: 'help-center', label: 'Help Center', path: '/help-center' },
        { id: 'download', label: 'Download', path: '/download' },
      ];
    }

    return (
      <>
        {navItems.map((item) => (
          <MobileNavItem
            key={item.id}
            onClick={() => {
              if (item.path.startsWith('#')) {
                setIsMobileMenuOpen(false);
                const element = document.getElementById(item.id);
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth' });
                } else {
                  handleNavigation(item.path, item.label);
                }
              } else {
                handleNavigation(item.path, item.label);
              }
            }}
          >
            {item.label}
          </MobileNavItem>
        ))}
        {isLoggedIn ? (
          <>
            <MobileNavItem
              onClick={() => {
                window.fbq && window.fbq('track', 'MobileNavAccountAccess');
                handleNavigation('/account', 'Account');
              }}
            >
              Account
            </MobileNavItem>
            <MobileNavItem
              onClick={() => {
                onLogout();
                window.fbq && window.fbq('track', 'MobileNavLogoutClick');
                setIsMobileMenuOpen(false);
              }}
            >
              Logout
            </MobileNavItem>
          </>
        ) : (
          <MobileNavItem
            onClick={() => {
              window.fbq && window.fbq('track', 'MobileNavLoginClick');
              handleNavigation('/login', 'Login');
            }}
          >
            Login
          </MobileNavItem>
        )}
      </>
    );
  };

  return (
    <>
      <MobileNavContainer isScrolled={isScrolled}>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <HamburgerIcon onClick={toggleMobileMenu}>
          <span></span>
          <span></span>
          <span></span>
        </HamburgerIcon>
      </MobileNavContainer>
      {isMobileMenuOpen && (
        <MobileNavMenu isOpen={isMobileMenuOpen}>
          <CloseButton onClick={() => setIsMobileMenuOpen(false)}>&times;</CloseButton>
          {renderMobileNavItems()}
        </MobileNavMenu>
      )}
    </>
  );
};

export default MobileNavigation;
