import React from 'react';
import styled from 'styled-components';

const LogoImage = styled.img`
  height: 150px;
  width: auto;
  user-select: none;
`;

const Logo: React.FC = () => {
  return (
    <LogoImage src="/risk_master_logo_scales.svg" alt="Risk Master Logo" />
  );
};

export default Logo;
