// src/components/Snackbar.tsx

import React from 'react';
import styled from 'styled-components';

interface SnackbarProps {
  message: string;
  isOpen: boolean;
  onClose: () => void;
}

const SnackbarContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-primary);
  color: var(--color-background);
  padding: 1rem 2rem;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1000;
`;

const Snackbar: React.FC<SnackbarProps> = ({ message, isOpen, onClose }) => {
  React.useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(onClose, 3000); // Auto-close after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose]);

  return <SnackbarContainer isOpen={isOpen}>{message}</SnackbarContainer>;
};

export default Snackbar;
