import React, { useState } from 'react';
import './../../styles/guide.css';

interface StepsContainerProps {
  currentStep: number;
  totalSteps: number;
  onStepChange: (step: number) => void;
  stepTitles: string[];
}

const StepsContainer: React.FC<StepsContainerProps> = ({ currentStep, totalSteps, onStepChange, stepTitles }) => {
  const [hoveredStep, setHoveredStep] = useState<number | null>(null);

  return (
    <div className="steps-container">
      {[...Array(totalSteps)].map((_, index) => (
        <div
          key={index}
          className={`step-rectangle ${index === currentStep ? 'active' : ''}`}
          onClick={() => onStepChange(index)}
          onMouseEnter={() => setHoveredStep(index)}
          onMouseLeave={() => setHoveredStep(null)}
          style={{ flexBasis: `calc(100% / ${totalSteps} - 10px)` }}
        >
          {hoveredStep === index && (
            <div className="step-title">{stepTitles[index]}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default StepsContainer;