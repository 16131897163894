// src/pages/Signup.tsx

import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { NotificationContext } from '../contexts/NotificationContext'; // Import NotificationContext

// Styled components for UI elements
const SignupContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--color-background);
`;

const SignupCard = styled.div`
  background-color: var(--color-card-bg);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h1`
  text-align: center;
  color: var(--color-text);
  margin-bottom: 1.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Input = styled.input`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  background-color: var(--color-input-bg);
  color: var(--color-text);
  width: 100%;
  max-width: 300px;
`;

const Button = styled.button`
  padding: 0.75rem;
  background-color: var(--color-primary);
  color: var(--color-background);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  max-width: 300px;
  margin-top: 0.5rem;

  &:hover {
    background-color: var(--color-secondary);
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const SignUpText = styled.p`
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
  color: var(--color-text);

  a {
    color: var(--color-primary);
    text-decoration: none;
    font-weight: bold;

    &:hover {
      color: var(--color-secondary);
      text-decoration: underline;
    }
  }
`;

const Signup: React.FC = () => {
  const navigate = useNavigate();
  const { showNotification } = useContext(NotificationContext); // Access Notification Context
  const [fullName, setFullName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Meta Pixel: Track Page View on Signup Page Load
  useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'ViewContent', { page: 'Signup' });
    }
  }, []);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Validate passwords match
    if (password !== confirmPassword) {
      showNotification("Passwords don't match.");
      return;
    }

    setIsSubmitting(true);

    // Meta Pixel: Track Signup Attempt
    if (window.fbq) {
      window.fbq('track', 'SignupAttempt');
    }

    try {
      // Signup API call
      const signupResponse = await axios.post(
        'https://riskmaster.pro/signup_proxy.php',
        { fullName, email, password },
        { headers: { 'Content-Type': 'application/json' } }
      );

      const signupData = signupResponse.data;

      if (signupData.status === 'success') {
        showNotification("Signup successful! Please check your email to confirm.");

        // Meta Pixel: Track Successful Signup
        if (window.fbq) {
          window.fbq('track', 'SignupSuccess');
        }

        // Navigate to login after successful signup
        navigate('/login');
      } else {
        showNotification(signupData.message || 'Signup failed.');
      }
    } catch (error) {
      showNotification('An error occurred. Please try again.');
      console.error('Signup Error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <SignupContainer>
      <SignupCard>
        <Title>Sign Up</Title>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder="Full Name"
            required
          />
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <Input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm Password"
            required
          />
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Sign Up'}
          </Button>
        </Form>
        <SignUpText>
          Already have an account? <Link to="/login">Login</Link>
        </SignUpText>
      </SignupCard>
    </SignupContainer>
  );
};

export default Signup;
