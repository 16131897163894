import React from 'react';
import styled from 'styled-components';

const ModalTitle = styled.h2`
  color: var(--color-primary);
  margin-bottom: 1rem;
`;

const ModalSection = styled.section`
  margin-bottom: 1.5rem;
`;

const ModalSectionTitle = styled.h3`
  color: var(--color-secondary);
  margin-bottom: 0.5rem;
`;

const ModalList = styled.ul`
  padding-left: 1.5rem;
  color: var(--color-primary);

  li {
    margin-bottom: 0.5rem;
    list-style: none; /* Remove the list marker specifically */
  }
  
  a {
    color: var(--color-primary);
    text-decoration: none;
    font-size: 1.2rem; /* Enlarge the link */
    font-weight: bold; /* Make the link bold for emphasis */
    &:hover {
      text-decoration: underline;
      color: var(--color-secondary);
    }
  }
`;

const Disclaimer: React.FC = () => (
  <>
    <ModalTitle>Disclaimer</ModalTitle>
    <ModalSection>
      <ModalSectionTitle>Trading Risks and Limitations</ModalSectionTitle>
      
      <p><strong>Risk of Loss:</strong> Trading and investing in securities, commodities, and other financial instruments involve significant risk. Most day traders and investors who use formula and chart-based investing strategies lose money. The risk of loss in trading and investing can be substantial. You should therefore carefully consider whether such trading and investing activities are suitable for you in light of your financial condition.</p>
      
      <p><strong>Educational Purpose:</strong> The Software provided by M.S Venture LLC is intended solely for educational and informational purposes. The Software is designed to assist users in managing risks while day trading but does not constitute financial advice. Decisions to buy, sell, hold, or trade in securities, commodities, and other investments involve risk and should be made based on the advice of qualified financial professionals.</p>
      
      <p><strong>Hypothetical Performance:</strong> All content and performance data provided by the Software are hypothetical and selected after the fact to demonstrate the capabilities of the Software. Past performance does not guarantee future results.</p>
      
      <p><strong>No Guarantee of Success:</strong> M.S Venture LLC makes no guarantees regarding the success or profitability of any trading strategy. The Software does not guarantee success in trading or investing and should not be relied upon as the sole basis for making financial decisions.</p>
      
      <p><strong>Limitations of Liability:</strong> M.S Venture LLC shall not be liable for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use the Software. The use of the Software is at the user's own risk. The Company shall not be liable for any damages arising from or related to the use of the Software.</p>
      
      <p><strong>Third-party Risk Disclosure:</strong> Users are advised to read the legal documentation and risk policies of any trading platform provider, brokerage, or financial institution before using their services or products. For more information about risk disclosures and other legal notices on supported trading platforms, please refer to the following link</p>
      
      <ModalList>
        <li><a href="https://ninjatrader.com/risk-disclosure-clearing/" target="_blank" rel="noopener noreferrer">NinjaTrader</a></li>
      </ModalList>
    </ModalSection>
  </>
);

export default Disclaimer;
