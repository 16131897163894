import React, { useState } from 'react';
import styled from 'styled-components';
import PrivacyPolicy from './PrivacyPolicy';
import Disclaimer from './Disclaimer';
import TermsOfService from './TermsOfService';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const FooterContainer = styled.footer`
  user-select: text;
  background-color: transparent;
  color: white;
  padding: 1rem 2rem;
`;

const FooterContent = styled.div`
  user-select: text;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
`;

const FooterSection = styled.div`
  user-select: text;
  display: flex;
  align-items: center;
  flex: 1;

  &:nth-child(2) {
    justify-content: center;
  }

  &:last-child {
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
    justify-content: center !important;
    margin: 10px 0;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1.5rem;
`;

const SocialLink = styled.a`
  color: inherit;
  transition: color 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;

const Copyright = styled.p`
  user-select: text;
  margin: 0;
  font-size: 0.9rem;
  color: white;
`;

const FooterLinks = styled.div`
  user-select: text;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
`;

const FooterLink = styled.a`
  user-select: text;
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.9rem;
  &:hover {
    text-decoration: underline;
    color: var(--color-secondary);
  }
`;

const ModalOverlay = styled.div`
  user-select: text;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  user-select: text;
  background-color: var(--color-card-bg);
  color: var(--color-text);
  padding: 2rem;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
`;

const CloseButton = styled.button`
  user-select: none;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--color-text-secondary);
  &:hover {
    color: var(--color-primary);
  }
`;

const Footer: React.FC = () => {
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);
  const [isTermsOfServiceOpen, setIsTermsOfServiceOpen] = useState(false);
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);

  const openPrivacyPolicy = () => setIsPrivacyPolicyOpen(true);
  const closePrivacyPolicy = () => setIsPrivacyPolicyOpen(false);

  const openTermsOfService = () => setIsTermsOfServiceOpen(true);
  const closeTermsOfService = () => setIsTermsOfServiceOpen(false);

  const openDisclaimer = () => setIsDisclaimerOpen(true);
  const closeDisclaimer = () => setIsDisclaimerOpen(false);

  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <Copyright>&copy; 2024 RiskMaster. All rights reserved.</Copyright>
        </FooterSection>
        
        <FooterSection>
          <SocialLinks>
            <SocialLink href="https://www.facebook.com/profile.php?id=61567667974892" target="_blank" aria-label="Facebook" style={{ color: '#4267B2' }}>
              <FaFacebook size={24} />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/riskmaster.pro/" aria-label="Instagram" style={{ color: '#C13584' }}>
              <FaInstagram size={24} />
            </SocialLink>
            {/* <SocialLink href="#" aria-label="LinkedIn" style={{ color: '#0077B5' }}>
              <FaLinkedin size={24} />
            </SocialLink> */}
            <SocialLink href="https://x.com/RiskMasterPro" aria-label="X" style={{ color: 'black' }}>
              <img src="/assets/x.svg" alt="X" width="24" height="24" />
            </SocialLink>
          </SocialLinks>
        </FooterSection>
        
        <FooterSection>
          <FooterLinks>
            <FooterLink href="mailto:info@riskmaster.pro">Contact</FooterLink>
            <FooterLink onClick={openTermsOfService}>Terms of Service</FooterLink>
            <FooterLink onClick={openPrivacyPolicy}>Privacy Policy</FooterLink>
            <FooterLink onClick={openDisclaimer}>Disclaimer</FooterLink>
          </FooterLinks>
        </FooterSection>
      </FooterContent>

      {isPrivacyPolicyOpen && (
        <ModalOverlay onClick={closePrivacyPolicy}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={closePrivacyPolicy}>&times;</CloseButton>
            <PrivacyPolicy />
          </ModalContent>
        </ModalOverlay>
      )}

      {isTermsOfServiceOpen && (
        <ModalOverlay onClick={closeTermsOfService}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={closeTermsOfService}>&times;</CloseButton>
            <TermsOfService closeTermsOfService={closeTermsOfService} openPrivacyPolicy={openPrivacyPolicy} />
          </ModalContent>
        </ModalOverlay>
      )}

      {isDisclaimerOpen && (
        <ModalOverlay onClick={closeDisclaimer}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={closeDisclaimer}>&times;</CloseButton>
            <Disclaimer />
          </ModalContent>
        </ModalOverlay>
      )}
    </FooterContainer>
  );
};

export default Footer;
