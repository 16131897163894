// src/pages/HelpCenter.tsx

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';

const HelpCenterContainer = styled.div`
  background-color: var(--color-section-1-bg);
  color: var(--color-text);
  padding: 2rem 0;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 8rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 1rem 1.5rem;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  color: white;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  width: 100%;
  margin-bottom: 2rem;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ContactSection = styled.div`
  text-align: center;
  margin-top: 2rem;
  color: #ccc;

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ContactLink = styled.a`
  color: var(--color-primary);
  text-decoration: underline;

  &:hover {
    color: var(--color-secondary);
  }
`;

interface HelpCenterProps {
  isLoggedIn: boolean;
  onLogout: () => void;
}

const HelpCenter: React.FC<HelpCenterProps> = ({ isLoggedIn, onLogout }) => {
  const navigate = useNavigate();

  // Meta Pixel: Track Page View on Help Center Load
  useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'ViewContent', { page: 'HelpCenter' });
    }
  }, []);

  // Meta Pixel: Track Help Video Watch
  const handleVideoInteraction = () => {
    if (window.fbq) {
      window.fbq('track', 'HelpVideoWatched', { page: 'HelpCenter' });
    }
  };

  // Meta Pixel: Track Contact Link Click
  const handleContactClick = () => {
    if (window.fbq) {
      window.fbq('track', 'HelpContactClick', { page: 'HelpCenter' });
    }
  };

  return (
    <HelpCenterContainer>
      <Navigation activeSection="help-center" handleScroll={() => {}} isLoggedIn={isLoggedIn} onLogout={onLogout} />
      <ContentWrapper>
        <Title>Help Center</Title>
        <VideoContainer>
          <iframe
            src="https://www.youtube.com/embed/nY-M8KC93mg"
            title="Help Center Video"
            frameBorder="0"
            allowFullScreen
            onLoad={handleVideoInteraction}
          />
        </VideoContainer>
        <ContactSection>
          <h3>Contact Us</h3>
          <p>If you have any questions, feel free to reach out to us at:</p>
          <ContactLink href="mailto:info@riskmaster.pro" onClick={handleContactClick}>
            info@riskmaster.pro
          </ContactLink>
        </ContactSection>
      </ContentWrapper>
      <Footer />
    </HelpCenterContainer>
  );
};

export default HelpCenter;
