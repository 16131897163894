import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Navigation from './Navigation';
import Footer from './Footer';

// Styled components for the Layout
const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main`
  flex: 1;
`;

// Props interface for the Layout component
interface LayoutProps {
  children: ReactNode;
  activeSection: string;
  handleScroll: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  isLoggedIn: boolean;
  onLogout: () => void;
}

// Layout component definition
const Layout: React.FC<LayoutProps> = ({ children, activeSection, handleScroll, isLoggedIn, onLogout }) => {
  return (
    <LayoutContainer>
      <Navigation 
        activeSection={activeSection} 
        handleScroll={handleScroll} 
        isLoggedIn={isLoggedIn} 
        onLogout={onLogout}
      />
      <MainContent>{children}</MainContent>
      <Footer />
    </LayoutContainer>
  );
};

export default Layout;